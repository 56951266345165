<script lang="ts" setup>
defineOptions({
  name: "DefaultLayout",
});

const bannerPromotion = ref<BannerPromotion>();

const fetchActiveBannerPromotion = async () => {
  try {
    bannerPromotion.value = await $fetch(`/api/banner-promotion/getPromotion`);
  } catch (e) {
    console.error(e);
    bannerPromotion.value = undefined;
  }
};

await fetchActiveBannerPromotion();
</script>

<template>
  <div class="min-h-dvh flex flex-col bg-white relative">
    <LayoutModalBackground />
    <LayoutHeader />
    <LayoutNotifications />
    <LayoutModalsContainer />
    <main>
      <slot />
    </main>
    <template v-if="bannerPromotion">
      <LayerMiniDrawer
        v-if="bannerPromotion?.promotionStyling"
        :name="bannerPromotion.name"
        :code="bannerPromotion.code"
        :desc="bannerPromotion?.promotionStyling?.description"
        :textcolor="bannerPromotion.promotionStyling.textColour"
        :bgcolor="bannerPromotion.promotionStyling.backgroundColour"
        :icon="bannerPromotion.promotionStyling.icon"
      />
    </template>
    <LayoutFooter class="m-t-auto" />
  </div>
</template>
